import React from 'react';
import MainNavigation from '../MainNavigation/MainNavigation';
import { Route, Switch } from 'react-router-dom';

import QuickNavigation from '../QuickNavigation/QuickNavigation';

import './Header.scss';

function Header (props) {
  return (
    <div id='header' className={props.classes}>
      <Switch>
        <Route exact path={props.pagesFullNav}>
          <MainNavigation classes="MainNavigation position-fixed" mobile={ props.mobile } />
          <QuickNavigation data="werke" mobile={ props.mobile } />
          { props.mobile ? ('') : (<QuickNavigation data="social" mobile={ props.mobile } />) }
        </Route>
        <Route path={props.pagesOnlyMainNav}>
          <MainNavigation classes="MainNavigation position-absolute" mobile={ props.mobile } />
        </Route>
      </Switch>
    </div>
  );
}

export default Header;
