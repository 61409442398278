
import React from 'react';
import SvgComponent from '../components/SvgComponent/SvgComponent';
import ScrollTop from '../components/ScrollTop/ScrollTop';

import '../styles/imprint.scss';

function Imprint () {
  return (
    <section className="imprint bgcolor--99black100">
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col contactInfos">
            <header className="row align-items-center">
              <div className="col-6 col-sm-6">
                <div className="Hdl Hdl--2">
                  Impressum / Datenschutz
                </div>
              </div>
              <div className="col-6 col-sm-6">
                <div className="logo d-flex justify-content-end">
                  <img className="img-fluid" src="/static/images/99media_Logo_grey.svg" alt="" />
                </div>
              </div>
            </header>
            <section className="row">
              <div className="col-xl-2 col-xxl-2 d-flex text-right">
                <SvgComponent svgId="contact_post" modifier="icon--lg" />
                <address className="text-grey">
                  99media<br />
                  Servet Öztürk<br />
                  Albstraße&nbsp;70/1<br />
                  72764&nbsp;Reutlingen
                </address>
              </div>
            </section>
            <section className="row">
              <div className="col-12 col-xl-3 col-xxl-2">
                <div className="info">
                  <SvgComponent svgId="contact_phone" />
                  <span className="phone text-grey">07121/6997059</span>
                </div>
                <div className="info">
                  <SvgComponent svgId="contact_fax"/>
                  <span className="fax text-grey">07121/6997058</span>
                </div>
                <div className="info">
                  <SvgComponent svgId="phone"/>
                  <span className="phone phone--mobile text-grey">01742343220</span>
                </div>
                <div className="info">
                  <SvgComponent svgId="contact_email"/>
                  <span className="email text-grey">info@99media.de</span>
                </div>
              </div>
              <div className="col-8 col-lg-6 col-xl-5 col-xxl-3 col-xxxl-2 offset-xl-2 offset-xxl-3 offset-xxxl-4">
                <p>
                  <span className="headline">Einzelunternehmen / Inhaber:</span><br/>
                  {/* <span className="d-block d-xs-none pb-1">Servet Öztürk<br /></span> */}
                  <span className="headline">Umsatzsteuer-IdNr.:</span><br/>
                  {/* <span className="d-block d-xs-none pb-1">DE307089583<br /></span> */}
                  <span className="headline">Umsetzung Webseite:</span><br/>
                  {/* <span className="d-block d-xs-none pb-1">99media<br /></span> */}
                  <span className="headline">Inhaltlich verantwortlich:</span><br />
                  {/* <span className="d-block d-xs-none pb-1">Servet Öztürk<br /></span> */}
                </p>
              </div>
              <div className="col-4 col-lg-6 col-xl-2 col-xxl-4 col-xxxl-4">
                <div className="d-flex justify-content-end">
                  <p>
                    Servet Öztürk<br />
                    DE307089583<br />
                    99media<br />
                    Servet Öztürk<br />
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="row">
          <article className="col imp_text">
            <p><strong>Haftungshinweis</strong></p>
            <p>Mit dem Zugriff auf die Website von 99media erklären Sie sich als Nutzer mit folgenden Nutzungsbedingungen für einverstanden:</p>

            <p><strong>Inhalt</strong></p>
            <p>Die Inhalte dieser Website wurden mit Sorgfalt und nach bestem Gewissen erstellt. Dennoch wird keine ausdrückliche
            noch stillschweigende Gewährleistung im Hinblick auf die Aktualität, Vollständigkeit, Verlässlichkeit und Richtigkeit oder
            die Inhalte der einzelnen Seiten übernommen.</p>

            <p><strong>Links</strong></p>
            <p>Auf die Rechtmäßigkeit der verlinkten Webseiten haben wir keinen Einfluss und tragen nicht die Verantwortung, deshalb
            distanzieren wir uns ausdrücklich von deren Inhalten und übernehmen keine Gewähr. Das Verlinken zu Inhalten von anderen
            Websites bedeutet nicht, dass wir die verlinkten Inhalte zu eigen machen. Zum Zeitpunkt der Verlinkung waren
            keine Rechtsverstöße erkennbar. Ohne konkrete Hinweise auf Rechtsverstöße ist eine ständige Kontrolle der externen
            Links nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden die Links unverzüglich entfernt.</p>

            <p><strong>Urheberrecht</strong></p>
            <p>Die Inhalte dieser Website unterliegen dem Urheberrecht und Leistungsschutzrecht. Sämtliche Arbeiten beinhalten geistige
            Schöpfungen der 99media oder Dritter und dürfen nicht aus dem Kontext genommen, vervielfältigt, kopiert, verändert,
            bearbeitet oder verbreitet werden. Weder in digitaler noch in sonstiger Form. Die Vervielfältigung von Text, Bild,
            Grafik und Video Inhalten Bedarf der Zustimmung von 99media oder dem jeweiligen Rechteinhaber. Zuwiderhandlungen
            sind nicht gestattet und strafbar.</p>

            <p><strong>Datenschutz</strong></p>
            <p>Durch Zugriff auf unsere Website werden persönliche Daten, wie beispielsweise IP-Adresse, Datum und Uhrzeit Ihrer Nutzung, etc. durch den Internetbrowser übermittelt und gespeichert. Diese Daten werden ausschließlich für technische Zwecke genutzt und wieder gelöscht.</p>

            <p>Falls Sie als Nutzer unserer Website über unser Kontaktformular mit uns in Kontakt treten, speichern und verarbeiten wir die von Ihnen angegebenen persönlichen Daten. Diese Daten nutzen wir um mit Ihnen zu kommunizieren und Ihr Anliegen zu bearbeiten. Ihre personenbezogenen Daten werden an Dritte nur zum Zwecke der Korrespondenz, beispielsweise für Briefverkehr, weitergegeben. Die von uns weitergegebenen Daten dürfen von Dritten lediglich zur Erfüllung der von uns vorgegebenen Aufgaben verwendet werden.</p>

            {/* <p>Auf dieser Webseite sind Funktionen von Drittanbietern, wie beispielsweise YouTube oder Vimeo eingebunden. Bei Nutzung dieser Funktionen wird eine Verbindung zu den Servern der Drittanbieter hergestellt. Somit wird dem Drittanbieter der Besuch unserer Website mitgeteilt.</p> */}

            {/* <p>Infos zu den Datenschutzerklärungen von Vimeo: <a className="link -content" href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">https://vimeo.com/privacy</a></p> */}

            {/* <p>Infos zu den Datenschutzerklärungen von YouTube: <a className="link -content" href="https://www.google.de/intl/de/policies" target="_blank" rel="noopener noreferrer">https://www.google.de/<wbr />intl/de/policies</a></p> */}

            <p>Die Website nutzt Google Analytics, einen Webanalysedienst der Google Inc. Google Analytics verwendet sogenannte „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen, einschließlich Ihrer IP-Adresse, über Ihre Benutzung dieser Website werden an einen Server von Google in den USA übertragen und dort gespeichert. Google benutzt diese Informationen um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>

            <p>Wir weisen ausdrücklich darauf hin, dass die Übertragung von Daten im Internet Sicherheitslücken aufweisen kann. Ein Schutz der Daten vor dem Zugriff Dritter kann von uns nicht gewährleistet werden. Die Nutzung der Website erfolgt auf eigene Gefahr des Nutzers.</p>

            <p>99media und alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.</p>
          </article>
        </div>
        <div className="row d-xxxxl-none">
          <div className="col">
            <ScrollTop />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Imprint;
