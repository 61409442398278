import React from 'react';
import Shuffle from 'shufflejs';
import './MasonryGrid.scss';
import GridItems from './GridItems';

class MasonryGrid extends React.Component {
  constructor(props) {
    super(props);

    this.element = React.createRef();
    this.sizer = React.createRef();
  }

  componentDidMount() {
    // The elements are in the DOM, initialize a shuffle instance.
    this.shuffle = new Shuffle(this.element.current, {
      itemSelector: '.grid-item',
      sizer: this.sizer.current,
    });
  }

  componentDidUpdate() {
    // Notify shuffle to dump the elements it's currently holding and consider
    // all elements matching the `itemSelector` as new.
    this.shuffle.resetItems();
  }

  componentWillUnmount() {
    // Dispose of shuffle when it will be removed from the DOM.
    this.shuffle.destroy();
    this.shuffle = null;
  }

  render() {
    // get data from Werke store them in variable
    const gridData = this.props.data;

    return (
      <div ref={this.element} className="row my-shuffle">

        {/* send gridData to GridItems Component */}
        <GridItems data={gridData} />

        <div ref={this.sizer} className="col-1 grid__sizer"></div>
      </div>
    );
  }
}

export default MasonryGrid;
