import React from 'react';

import './index.scss';

function simpleLogin() {
  const value = document.getElementById('psw').value;

  if (value === '0990') {
    document.getElementById('error-psw').classList.add('d-none');
    const url = '/dokumente/agb/AGB_99media.pdf';
    window.open(url);
  } else {
    document.getElementById('error-psw').classList.remove('d-none');
  }

  return false;
}

function Agb () {
  return (
    <div id="agb" className="Agb section animation animation--fromRight tint tint--85 fixed fixed--default d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-6 col-sm-4 col-lg-3 col-xl-2 mx-auto text-center">
            <form onSubmit={simpleLogin}>
              <div>
                <input id="psw" name="psw" type="password" className="form-control-sm required" placeholder="Passwort" required />
              </div>
              <div>
                <button className="btn -send" type="submit">Login</button>
              </div>
              <div id="error-psw" className="form-messages error-psw d-none">
                BItte korrektes Passwort eingeben.
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agb;
