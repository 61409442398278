import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';

import SvgComponent from '../SvgComponent/SvgComponent';

import './QuickNavigation.scss';

import Data from './Data.json';

const QuickNavigation = (props) => {
  const isMobile = props.mobile;

  useEffect(() => {
    if (isMobile && document.querySelector('.QuickNavigation--right')) {
      if (window.location.pathname === '/Werkemenu/') {
        document.querySelector('.QuickNavigation--right').classList.add('QuickNavigation--open');
      } else {
        document.querySelector('.QuickNavigation--right').classList.remove('QuickNavigation--open');
      }
    }
  });

  const Items = (props) => {
    const data = Data.QuickNavigation[props.data];

    const Link = (props) => {
      if (props.data.group === 'social') {
        if (props.data.disabled) {
          return (
            <span className="QuickNavigation__link QuickNavigation__link--disabled">
              <SvgComponent svgId={props.data.icon} />
            </span>
          );
        } else {
          return (
            <a className="QuickNavigation__link" href={props.data.link} target={props.data.target} rel="noopener noreferrer">
              <SvgComponent svgId={props.data.icon} />
            </a>
          );
        }
      } else {
        if (props.data.disabled) {
          return (
            <span className={'QuickNavigation__link QuickNavigation__link--disabled'}>
              <span className="QuickNavigation__tooltip">{props.data.name}</span>
              <SvgComponent svgId={props.data.icon} modifier="icon icon--md icon--border" />
            </span>
          );
        } else {
          return (
            <NavLink to={props.data.link} className={'QuickNavigation__link'} activeClassName="QuickNavigation__link--active">
              <span className="QuickNavigation__tooltip">{props.data.name}</span>
              <SvgComponent svgId={props.data.icon} modifier="icon icon--md icon--border" />
            </NavLink>
          );
        }
      }
    }

    return (
      <>
        { data.map((item, index) => (
          <li className="QuickNavigation__item" key={index}>
            <Link data={item} />
          </li>
        )) }
      </>
    );
  }


  return (
    <>
      { props.data === 'werke' && isMobile ? (
        <>
          <Link to="/Werkemenu/" className="btn btn-clean toggle-werke">
            <SvgComponent svgId="idee_icon" modifier="icon--md icon--border" />
          </Link>
          <div className="QuickNavigation QuickNavigation--right">
            <Link to='/' className="btn btn-clean btn-icon" type='button'>
              <SvgComponent svgId="close" />
            </Link>
            <ul className="QuickNavigation__items">
              <Items data="werke" />
            </ul>
          </div>
        </>
      ) : (
        <div className={`QuickNavigation ${props.data === 'social' ? 'QuickNavigation--left' : 'QuickNavigation--right'}`}>
          <ul className="QuickNavigation__items">
            <Items data={props.data} />
          </ul>
        </div>
      ) }
    </>
  );
};

export default QuickNavigation;
