import React from 'react';
import { NavLink } from 'react-router-dom';

import './Legal.scss';

const Legal = () => {
  return (
    <div className="Legal">
      <NavLink to="/impressum" target="_blank">
        Impressum / Datenschutz
      </NavLink>
      <NavLink to="/AGB/" title="AGB" target="_blank">
        AGB
      </NavLink>
      {/* <a href="https://webftp.all-inkl.com/" target="_blank" rel="noopener noreferrer" title="Kunden">
        Kunden
      </a> */}
    </div>
  );
}

export default Legal;
