import React, { useState } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CookieConsent from "react-cookie-consent";


import Agency from '../Agency/Agency';
import Brands from '../Brands/Brands';
import Jobs from '../Jobs/Jobs';
import Contact from '../Contact/Contact';
import Werke from '../Werke/Werke';
import Werkepage from '../Werkepage/Werkepage';
import Profilpage from '../Profilpage';
import Footer from '../Footer/Footer';
import Agb from '../Agb';

import './Content.scss';

function Content (props) {
  const [profile, toggleProfile] = useState(false);

  return (
    <>
      <div id="content" className="Content">
        <Route render={({location}) => (
          <TransitionGroup className="transition-group h-100">
            <CSSTransition key={location.key} timeout={450} classNames="fade" onExiting={() => {toggleProfile(false)} }>
              <Switch location={location}>
                <Route exact path="/Agentur/" render={() => <Agency mobile={props.mobile} /> } />
                <Route exact path="/Brands/" render={() => <Brands mobile={props.mobile} /> } />
                <Route exact path="/Jobs/" render={() => <Jobs mobile={props.mobile} /> } />
                <Route exact path="/Kontakt/" render={() => <Contact mobile={props.mobile} /> } />
                <Route exact path="/Werke/Konzept/" render={() => <Werke slides={3} tint={true} group="concept" mobile={props.mobile} fixed={true} /> } />
                <Route exact path="/Werke/Video/" render={() => <Werke slides={3} tint={true} group="video" mobile={props.mobile} fixed={true} /> } />
                <Route exact path="/Werke/Foto/" render={() => <Werke slides={3} tint={true} group="photo" mobile={props.mobile} fixed={true} /> } />
                <Route exact path="/Werke/360/" render={() => <Werke slides={3} tint={true} group="360" mobile={props.mobile} fixed={true} /> } />
                <Route exact path="/Werke/Gestaltung/" render={() => <Werke slides={3} tint={true} group="gestaltung" mobile={props.mobile} fixed={true} /> } />
                <Route exact path="/Werke/Audio/" render={() => <Werke slides={3} tint={true} group="audio" mobile={props.mobile} fixed={true} /> } />
                <Route exact path="/Werke/Promotion/" render={() => <Werke slides={3} tint={true} group="promotion" mobile={props.mobile} fixed={true} /> } />
                <Route exact path="/Werke/Freelancer/" render={() => <Werke slides={3} tint={true} group="freelancer" mobile={props.mobile} fixed={true} /> } />
                <Route exact path="/Werke/Coaching/" render={() => <Werke slides={3} tint={true} group="coaching" mobile={props.mobile} fixed={true} /> } />
                <Route path="/Werke/" render={() => <Werkepage mobile={props.mobile} /> } />
                <Route path="/Profil/" render={() => <Profilpage mobile={props.mobile} /> } />
                <Route exact path="/AGB/" render={() => <Agb mobile={props.mobile} /> } />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )} />
        <CookieConsent
          overlay={true}
          overlayStyle={{  }}
          location="bottom"
          buttonText="Einverstanden"
          cookieName="acceptedCookies"
          cookieValue={true}
          style={{ position: 'absolute', backgroundColor: '#1E2020',  top: '100%', left: '0', bottom: 'auto', transform: 'translateY(-100%)', height: 'fit-content', width: '100%', padding: '15px' }}
          buttonWrapperClasses="cookie-button-wrapper"
          buttonClasses="accept-button"
          contentClasses="content"
          expires={1}
          onAccept={({ acceptedByScrolling }) => {
            if (!acceptedByScrolling) {
              // console.log("Accept was triggered by clicking the Accept button");
              window.ga('create', 'UA-147393816-1', 'auto');
              window.ga('send', 'pageview');
            }
          }}
          flipButtons
          declineButtonText="Ablehnen"
          onDecline={() => {
            // console.log("Declined");
            window.ga('create', 'UA-147393816-1', {
              'storage': 'none',
              'anonymizeIp': true
            });
            window.ga('send', 'pageview');
          }}
          sameSite="strict"
        >
          <div style={{}}>Bei Nutzung dieser Webseite akzeptieren Sie unsere <Link className="link" to="/Impressum/" target="_blank">Datenschutzerklärung</Link> und das verwenden von Cookies.</div>
        </CookieConsent>
      </div>
      { props.hasProfile ? (
        <Footer profile={profile} toggleProfile={toggleProfile} mobile={props.mobile}/>
      ) : (
        ''
      ) }
    </>
  );
}

export default Content;
