import React from 'react';
import Swiper from 'react-id-swiper';
import swiperGlobalOptions from '../Swiper/swiperGlobalOptions';

import './Jobs.scss';
import '../Textblock/Textblock.scss';

import Data from './Data.json';

const Jobs = () => {
  const page = {
    ...swiperGlobalOptions,
    mousewheel: {
      invert: false,
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    //   clickable: true,
    //   dynamicBullets: true,
    // },
    spaceBetween: 10,
    slidesPerView: 1,
    breakpoints: {
      812: {
        spaceBetween: 0
      }
    }
  }

  const article = {
    ...swiperGlobalOptions,
    direction: 'vertical',
    spaceBetween: 0,
    slidesPerView: 'auto',
    freeMode: true,
    allowTouchMove: false,
    breakpoints: {
      767: {
        allowTouchMove: true
      }
    }
  }

  return (
    <div id="jobs" className="Jobs section animation animation--fromRight tint tint--85 fixed fixed--default">

      <picture>
        <source data-srcset="
          /static/images/backgrounds/jobs/768.jpg 768w,
          /static/images/backgrounds/jobs/992.jpg 992w,
          /static/images/backgrounds/jobs/1920.jpg 1920w,
          /static/images/backgrounds/jobs/2560.jpg 2560w"
        />
        <img className="background-image lazyload" data-sizes="auto" data-src="/static/images/backgrounds/jobs/1920.jpg" alt="" />
      </picture>

      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 col-xl-8 mx-auto h-100 position-static">
            <Swiper {...page} containerClass="swiper-container--page swiper-container">
              {Data.Jobs.map((slide, index) => (
                <div className="swiper-slide" key={index}>
                  <div className="wrapper">
                    <div className="row h-100">
                      <div className="col-lg-8 col-xl-12 mx-auto h-100">
                        <header className="mb-3 d-none d-lg-block">
                          <div className="Hdl Hdl--1 text-white">
                            {slide.name}
                          </div>
                        </header>
                        <Swiper {...article} containerClass="position-static swiper-container h-100">
                          {slide.blocks.map((block, index) => (
                            <div key={index}>
                              <article className="Textblock row">
                                <div className="col-9 col-lg-12">
                                  {index === 0 ? (
                                    <header className="mt-5 mb-md-3 d-block d-lg-none">
                                      <div className="Hdl Hdl--1 text-white">
                                        {slide.name}
                                      </div>
                                    </header>
                                  ) : (
                                    null
                                  )}
                                  <div className="Textblock__header mb-lg-2 mb-xl-3">
                                    <div className="Hdl Hdl--2 text-gray">
                                      {block.name}
                                    </div>
                                  </div>
                                  <div className="Textblock__content text-justify">
                                    <p>{block.text}</p>
                                  </div>
                                </div>
                              </article>
                            </div>
                          ))}
                        </Swiper>
                        {/* <div className="article-pagination"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jobs;
