import React, { Suspense, lazy } from 'react';
import Swiper from 'react-id-swiper';
import swiperGlobalOptions from '../Swiper/swiperGlobalOptions';

import './index.scss';

const Intro = lazy(() => import('./Intro'));
const Team = lazy(() => import('./Team'));
const Prozesskette = lazy(() => import('./Prozesskette'));
const Philosophie = lazy(() => import('./Philosophie'));
const Skills = lazy(() => import('./Skills'));
const Portfolio = lazy(() => import('./Portfolio'));

const Agency = (props) => {
  const options = {
    ...swiperGlobalOptions,
    mousewheel: {
      invert: false,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      dynamicBullets: true,
    },
    spaceBetween: 0,
    slidesPerView: 1
  }

  return (
    <div id="agency" className="Agency section animation animation--fromRight tint tint--85 d-flex align-items-center h-100 fixed fixed--default">

      <picture>
        <source data-srcset="
        /static/images/backgrounds/agency/768.jpg 768w,
        /static/images/backgrounds/agency/992.jpg 992w,
        /static/images/backgrounds/agency/1920.jpg 1920w"
      />
        <img className="background-image lazyload" data-sizes="auto" src="/static/images/backgrounds/agency/1920.jpg" alt="" />
      </picture>

      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 col-xl-8 mx-auto h-100">
            <Swiper {...options} containerClass="swiper-container--page swiper-container">
              <div>
                <div className="Agency__Intro col-9 col-sm-8 col-lg-8 col-xl-9 col-xxxxl-7">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Intro mobile={props.mobile} />
                  </Suspense>
                </div>
              </div>
              <div>
                <div className="Agency__Team col-6 col-lg-9 col-xl-12 col-xxxxl-10">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Team mobile={props.mobile} />
                  </Suspense>
                </div>
              </div>
              <div>
                <div className="Agency__Philosophie col-9 col-md-8 col-lg-7">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Philosophie mobile={props.mobile} />
                  </Suspense>
                </div>
              </div>
              <div>
                <div className="Agency__Skills col-9 col-xs-ls-8 col-sm-8 col-lg-9 col-xl-12 col-xxxl-10 col-xxxxl-8">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Skills mobile={props.mobile} />
                  </Suspense>
                </div>
              </div>
              <div>
                <div className="Agency__Prozesskette col-9">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Prozesskette mobile={props.mobile} />
                  </Suspense>
                </div>
              </div>
              <div>
                <div className="Agency__Portfolio col-6 col-sm-4 col-lg-10 col-xl-8">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Portfolio mobile={props.mobile} />
                  </Suspense>
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agency;
