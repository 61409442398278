import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import SvgComponent from '../SvgComponent/SvgComponent';

import Data from './Data.json';

import Legal from '../Legal/Legal';
import QuickNavigation from '../QuickNavigation/QuickNavigation';

import './MainNavigation.scss';

function MainNavigation (props) {
  const [mobileNav, toggleMobileNav] = useState(false);
  const isMobile = props.mobile;

  useEffect(() => {
    if (mobileNav) {
      document.querySelector('.MainNavigation__nav').classList.add('MainNavigation__nav--open');
    } else {
      document.querySelector('.MainNavigation__nav').classList.remove('MainNavigation__nav--open');
    }
  });

  const Menu = () => {
    return (
      <>
        {Data.MainNavigation.map((item, index) => (
          <li className="MainNavigation__item" key={index}>
            <NavLink exact to={isMobile ? item.mobileTo : item.to } className="MenuLink" activeClassName="MenuLink--active" name={item.name} onClick={() => toggleMobileNav(false)}>{item.name}</NavLink>
          </li>
        ))}
      </>
    );
  }
  return (
    <nav className={props.classes}>
      <div className="container">
        <div className="MainNavigation__row">
          { isMobile ? (
            <div className="MainNavigation__navToggle">
              <button className="btn btn-clean btn-icon" type="button" onClick={() => toggleMobileNav(true)}>
                <SvgComponent svgId="menu3" modifier="color--white" />
              </button>
            </div>
          ) : false }
          <div className="MainNavigation__nav">
            { isMobile ? (
              <div className="MainNavigation__navToggle">
                <button className="btn btn-clean btn-icon" type="button" onClick={() => toggleMobileNav(false)}>
                  <SvgComponent svgId="close" modifier="color--white" />
                </button>
              </div>
            ) : false }
            <div className="MainNavigation__items">
              <ul>
                <Menu />
              </ul>
              { isMobile ? (<QuickNavigation data="social" />) : '' }
            </div>
            { isMobile ? (<Legal />) : '' }
          </div>
          <div className="MainNavigation__brand">
            <a href="/" className="MenuLink">
              { isMobile ? (
                <img className="logo" src="/static/images/99_LOGO_WEISS.svg" alt="" />
              ) : (
                <img className="logo" src="/static/images/99media_Logo_grey.svg" alt="" />
              ) }
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MainNavigation;
