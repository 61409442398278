
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from '../components/Header/Header';
import StartpageBackground from '../components/StartpageBackground';
import Content from '../components/Content/Content'

import Imprint from './imprint';

// Atoms CSS
import '../components/MenuLink/MenuLink.scss';
import '../components/Hdl/Hdl.scss';
import '../components/Animation/Animation.scss';

function App (props) {
  const fullNavPages = ['/', '/Agentur', '/Brands', '/Jobs', '/Kontakt', '/Werkemenu/', '/Werke/Konzept', '/Werke/Video', '/Werke/Foto', '/Werke/360', '/Werke/Gestaltung', '/Werke/Audio', '/Werke/Promotion', '/Werke/Freelancer', '/Werke/Coaching'];
  const onlyMainNavPages = ['/Werke/', '/Profil/'];
  const noNavPages = ['/AGB'];
  const mobile = window.matchMedia('(max-width: 992px)');
  const [isMobile, detectMobile] = useState(mobile.matches);

  mobile.addListener(function(m) {
    if(m.matches) {
      // Changed to portrait
      detectMobile(true);
    }
    else {
      // Changed to landscape
      detectMobile(false);
    }
  });

  return (
    <Switch>
      <Route exact path={fullNavPages}>
        <div id="page" className="page position-fixed">
          <Header
            classes="header position-fixed"
            mobile={isMobile}
            pagesFullNav={fullNavPages}
            pagesOnlyMainNav={onlyMainNavPages} />
          <Content hasProfile={true} mobile={isMobile} />
          <StartpageBackground hidden={ props.location.pathname === '/' ? false : true } mobile={isMobile} />
        </div>
      </Route>
      <Route path={onlyMainNavPages}>
        <div id="page" className="page page--werke position-relative">
          <Header
            classes="header position-absolute"
            mobile={isMobile}
            pagesFullNav={fullNavPages}
            pagesOnlyMainNav={onlyMainNavPages} />
          <Content hasProfile={false} mobile={isMobile} />
        </div>
      </Route>
      <Route path={noNavPages}>
        <Content hasProfile={false} mobile={isMobile} />
      </Route>
      <Route path="/impressum" component={Imprint} />
    </Switch>
  );
}

export default App;
