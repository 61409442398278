import React from 'react';
import Plyr from 'plyr';

function Item(props) {
  document.querySelectorAll('.player').forEach(element => new Plyr(element, {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
  }));

  if (props.type === 'video') {
    return (
      <div className="grid-item__inner grid-item__inner--video">
        <video disablePictureInPicture className="player" playsInline controls poster={props.poster}>
          <source src={RESOURCES.video + props.src.webm} type="video/webm" />
        </video>
      </div>
    )
  } else if (props.type === 'audio') {
    return (
      <div className="grid-item__inner grid-item__inner--audio">
        <picture>
          <source srcSet={props.poster + ".jpg" } />
          <img className="img-fluid" src={props.poster + ".jpg" } alt="" />
        </picture>
        <audio className="player" controls>
          <source src={RESOURCES.audio + props.src} type="audio/mp3" />
        </audio>
      </div>
    )
  } else {
    return (
      <div className="grid-item__inner grid-item__inner--image">
        <picture>
          <source data-srcset={props.src.sm + ".jpg 768w, " + props.src.lg + ".jpg 1024w, " + props.src.xl + ".jpg 1920w"} type="image/jpg" />
          <img className="img-fluid" src={props.src.xl + ".jpg" } alt="" />
        </picture>
      </div>
    )
  }
}

function GridItems (props) {
  const gridItemsArray = props.data;

  return (
    <>
      { gridItemsArray.map((item, index) => {
        return (
          <div key={index} className={`grid-item ${item.columns}`}>
            <Item type={item.type} src={item.src} poster={item.poster} />
          </div>
        )
      }) }
    </>
  );
}

export default GridItems;
