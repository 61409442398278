import React from 'react';
import Swiper from 'react-id-swiper';
import swiperGlobalOptions from '../Swiper/swiperGlobalOptions';
import { NavLink } from 'react-router-dom';

import SvgComponent from '../SvgComponent/SvgComponent';
import TeamContact from './Team';

import './Contact.scss';

const Contact = (props) => {
  const options = {
    ...swiperGlobalOptions,
    mousewheel: {
      invert: false,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      dynamicBullets: true,
    },
    spaceBetween: 0,
    slidesPerView: 1
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const form = document.querySelector('#ajax-contact');
    const formMessages = document.querySelector('.form-messages');

    if (!event.target.checkValidity()) {
      // form is invalid! so we do nothing
      formMessages.innerHTML = 'Bitte alle Felder ausfüllen und Datenschutzerklärung akzeptieren.';

      for (const elem of form.querySelectorAll(':invalid')) {
        elem.classList.add('error');
      }

      return;
    } else {
      formMessages.innerHTML = 'Deine Nachricht wurde versendet und ist in Bearbeitung.';

      fetch('/mailer.php', {
        method: 'POST',
        body: data,
      });
    }
  }

  const Address = () => {
    return (
      <div className="Address">
        <div className="box -address box--br">
          <SvgComponent svgViewBox="0 0 512 512" svgId="contact_post" modifier="icon--lg" />
          <div className="Textblock -address">
            99media<br />
            Albstraße 70/1<br />
            72764 Reutlingen
          </div>
        </div>
        <div className="box -contact List list-unstyled">
          <ul className="List -contacts list-unstyled">
            <li className="d-flex align-items-center">
              <p className="text -icon -first">
                <SvgComponent svgViewBox="0 0 12.09 17.29" svgId="contact_phone" modifier="d-inline" />
                <span>07121/6997059</span>
              </p>
            </li>
            <li className="d-flex align-items-center">
              <p className="text -icon">
                <SvgComponent svgViewBox="0 0 17.03 15.82" svgId="contact_fax" modifier="d-inline" />
                <span>07121/6997058</span>
              </p>
            </li>
            <li className="d-flex align-items-center">
              <p className="text -icon -smaller">
                <SvgComponent svgViewBox="0 0 14.25 14.47" svgId="contact_email" modifier="d-inline" />
                info@99media.de
              </p>
            </li>
          </ul>
          <ul className="List -social list-unstyled list-inline text-md-right">
            <li className="list-inline-item mt-0">
              <span>
                <SvgComponent svgId="xing_icon" />
              </span>
            </li>
            <li className="list-inline-item mt-0">
              <span>
                <SvgComponent svgId="instagram_icon" />
              </span>
            </li>
            <li className="list-inline-item mt-0">
              <span>
                <SvgComponent svgId="facebook_icon" />
              </span>
            </li>
            <li className="list-inline-item mt-0">
              <a className="" href="https://goo.gl/maps/TLjYqHegCcnbd4Qr6" target="_blank" rel="noopener noreferrer">
                <SvgComponent svgId="gmaps" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  };

  const Form = (props) => {
    return (
      <>
        { window.matchMedia(props.media).matches ? (
            <form id="ajax-contact" onSubmit={handleSubmit} className="Form -contact" method="post" noValidate>
              <div className="container-fluid">
                <div className="row no-gutters">
                  <div className="col">
                    <input id="name" name="name" type="text" className="form-control-sm required" placeholder="Name" required />
                  </div>
                  <div className="col">
                    <input id="email" name="email" type="email" className="form-control-sm required" placeholder="E-Mail" required />
                  </div>
                  <div className="col">
                    <input id="phone" name="phone" type="text" className="form-control-sm required" placeholder="Telefon" required />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <textarea id="message" name="message" className="form-control required" placeholder="" required></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button className="btn -send" value="Senden" type="submit">Senden</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-check d-flex align-items-center">
                      <input id="dataprotection" name="dataprotection" className="form-check-input" type="checkbox" value="false" required />
                      <label className="form-check-label" htmlFor="dataprotection">
                        <NavLink className="MenuLink" to="/impressum" target="_blank">Datenschutzerklärung</NavLink><span className="MenuLink__text">&nbsp;gelesen und akzeptiert.</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div id="form-messages" className="form-messages"></div>
                  </div>
                </div>
              </div>
            </form>
        ) : '' }
      </>
    )
  };

  return (
    <section id="contact" className="Contact section animation animation--fromRight tint tint--85 fixed fixed--default d-flex align-items-center">

      <picture>
        <source data-srcset="
        /static/images/backgrounds/contact/768.jpg 768w,
        /static/images/backgrounds/contact/992.jpg 992w,
        /static/images/backgrounds/contact/1920.jpg 1920w,
        /static/images/backgrounds/contact/2560.jpg 2560w"
      />
        <img className="background-image lazyload" data-sizes="auto" data-src="/static/images/backgrounds/contact/1920.jpg" alt="" />
      </picture>

      <div className="container h-100">
        <div className="row h-100">
          <div className="col-12 col-xl-8 h-100 mx-auto">
            <Swiper {...options} containerClass="swiper-container h-100">
              <div className="swiper-slide">
                <div className="Contact__contact col-9 col-lg-12 d-flex align-items-center align-items-md-stretch justify-content-center">
                  <Address />
                  <Form media="(min-width: 1200px)" />
                </div>
              </div>
              <div className="swiper-slide d-flex d-xl-none justify-content-center flex-column">
                <div className="col-9 col-lg-12 d-flex align-items-center justify-content-center h-100">
                  <Form media="(max-width: 1199px)" />
                </div>
              </div>
              <div className="swiper-slide">
                <div className="position-static col-6 col-md-12 col-xxxxl-10 d-flex align-items-lg-center text-center h-100">
                  <TeamContact mobile={props.mobile} />
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
