import React from 'react';
// import Video from '../Video/Video';

import './StartpageBackground.scss';

const StartpageBackground = (props) => {
  return (
    <div className="StartpageBackground fixed fixed--top-center tint tint--85" style={{display: props.hidden ? "none" : "" }}>
      { props.mobile ? (
        <>
          <img className="logo fixed fixed--top-center" src="/static/images/99media_Logo_grey.svg" alt="" />
          <picture>
            <source data-srcset="
              /static/images/backgrounds/startpage/768.jpg 768w,
              /static/images/backgrounds/startpage/992.jpg 992w,
              /static/images/backgrounds/startpage/1920.jpg 1920w
            " />
            <img className="bgimage fixed fixed--top-center lazyload" data-sizes="auto" data-src="/static/images/backgrounds/startpage/1920.jpg" alt="" />
          </picture>
        </>
        ) : (
          <>
            <img className="logo fixed fixed--top-center" src="/static/images/99media_Logo_grey.svg" alt="" />
            <picture>
              <source data-srcset="
                /static/images/backgrounds/startpage/768.jpg 768w,
                /static/images/backgrounds/startpage/992.jpg 992w,
                /static/images/backgrounds/startpage/1920.jpg 1920w
              " />
              <img className="bgimage fixed fixed--top-center lazyload" data-sizes="auto" data-src="/static/images/backgrounds/startpage/1920.jpg" alt="" />
            </picture>
          </>
          // <Video classes="video fixed fixed--top-center" videoSrc="/static/_video/startpage/startpage.webm" muted={true} loop={true} />
      ) }
    </div>
  );
}

export default StartpageBackground;
