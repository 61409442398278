import React from 'react';
import MasonryGrid from '../MasonryGrid/MasonryGrid';
import Teaser from '../Teaser/Teaser';
import SvgComponent from '../SvgComponent/SvgComponent';
import Werke from '../Werke/Werke';

import ScrollTop from '../ScrollTop/ScrollTop';

import './Werkepage.scss';

import Data from './data';

let project;

const projectData = () => {
  const pathArray = window.location.pathname.split('/');
  const target = pathArray.pop() || 'default'; // Kebap

  // transform kebap to camelcase
  const newTarget = target.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });

  project = Data[newTarget];
  return project;
}

const Filter = (props) => {
  return(
    <>
      {props.project.filter.map((filter, index) => (
        <li key={index} className="Filter__item">
          <span className={ filter.active ? "Filter__link Filter__link--active" : "Filter__link" }>
            <SvgComponent svgId={filter.icon} modifier="icon--md icon--border" />
          </span>
        </li>
      ))}
    </>
  )
}

const Werkepage = (props) => {
  const project = projectData();
  return (
    <div className="Werkepage bgcolor--white">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <Teaser imgSrc={ project.headerImg } />
          <div className="Werkepage__content">
            <div className="container container--werke">
              <div className="row">
                <div className="col-12">
                  <div className="Hdl Hdl--1">
                    {project.name}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="Werkepage -filter col-lg-6 d-flex align-items-lg-end justify-content-lg-start justify-content-start align-items-center">
                  <div className="Filter">
                    <ul className="Filter__items ">
                      <Filter project={project}/>
                    </ul>
                  </div>
                </div>
                <div className="Werkepage -description col-lg-6">
                  <div className="text-justify">
                    <p>{project.description}</p>
                  </div>
                </div>
              </div>
              <MasonryGrid data={project.masonry} />
            </div>
          </div>

          <Werke slides={3} position={'horizontal'} tint={true} group={project.group} mobile={props.mobile} fixed={false} />

          <Teaser imgSrc={ project.footerImg } />
          <div className="container">
            <ScrollTop />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Werkepage;
