import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';

// import 'lazysizes/plugins/parent-fit/ls.parent-fit';
// import 'lazysizes/plugins/bgset/ls.bgset';
// import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes';

import * as serviceWorker from './serviceWorker';

import Startpage from './pages/startpage';

import './styles/index.scss';

ReactDOM.render(
  <BrowserRouter>
    <Route path="/" component={Startpage} />
  </BrowserRouter>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// window.lazySizesConfig = window.lazySizesConfig || {};
// window.lazySizesConfig.customMedia = {
//     '--xs': '(min-width: 0px)',
//     '--md': '(min-width: 769px)',
//     '--lg': '(min-width: 993px)',
//     '--xl': '(min-width: 1201px)',
//     '--xxl': '(min-width: 1441px)',
//     '--xxxl': '(min-width: 1921px)'
// };
