// import React, {useState, useEffect} from 'react';
import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
import swiperGlobalOptions from '../Swiper/swiperGlobalOptions';
import { Popover, PopoverBody } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

import SvgComponent from '../SvgComponent/SvgComponent';

import Data from './Data.json';

import '../Agency/Team.scss';

const Team = () => {
  const options = {
    ...swiperGlobalOptions,
    slidesPerView: 3,
    allowTouchMove: false,
    centeredSlides: true,
    breakpoints: {
      992: {
        slidesPerView: 1,
        centeredSlides: true,
        direction: 'vertical',
        allowTouchMove: true
      }
    }
  }

  const PopoverItem = props => {
    const { id, item } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);

    const toggle = () => setPopoverOpen(!popoverOpen);

    return (
      <li className="list-inline-item" key={id}>
        <span id={'popover-' + id} className="Team__link Team__link--tt d-flex btn-clean">
            <SvgComponent svgId={item.icon} modifier="icon--lg" />
        </span>
        <Popover
          id={id}
          key={id}
          container={document.getElementById('popover-' + id)}
          trigger="legacy"
          placement="bottom"
          target={'popover-' + id}
          isOpen={popoverOpen}
          toggle={toggle}
        >
          <PopoverBody>
            <a href={item.link} rel="noopener noreferrer" target={item.target ? item.target : '_blank'}>{item.text}</a>
          </PopoverBody>
        </Popover>
      </li>
    );
  };

  return (
    <div id="team" className="Team Team--contact w-100 position-static">
      <div className="d-none align-items-center justify-content-center">
        <div className="Hdl Hdl--1 text-white">Team Play</div>
        <span>&nbsp;</span>
        <div className="Hdl Hdl--1 text-gray">Team Work</div>
      </div>
      <Swiper {...options} containerClass="position-static swiper-container">
        { Data.contact.team.map((item, i) => (
          <div className="swiper-slide" key={i}>
            <div className="Team__item">
              <div className="Team__image">
                <picture>
                  <source srcSet={item.image + ".jpg"} />
                  <img className="Img__work -active img-fluid rounded-circle" src={item.image + ".jpg"} alt={item.name} />
                </picture>
              </div>
              <div className="Hdl Hdl--4 Hdl--active">{item.name}</div>
              {ReactHtmlParser(item.position)}
              <ul className="list-inline">
                { item.contact.map((popover, index) => (
                  <PopoverItem key={index} item={popover} id={i + '-' + index} />
                )) }
              </ul>
            </div>
          </div>
        )) }
      </Swiper>
    </div>
  )
}

export default Team;
