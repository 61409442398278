import React from 'react';
import MasonryGrid from '../MasonryGrid/MasonryGrid';
import Teaser from '../Teaser/Teaser';
// import Profil from '../Profil';
import ScrollTop from '../ScrollTop/ScrollTop';

import './index.scss';

import Data from './data';

let project;

const projectData = () => {
  const pathArray = window.location.pathname.split('/');
  const target = pathArray.pop() || 'default'; // Kebap

  // transform kebap to camelcase
  const newTarget = target.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });

  project = Data[newTarget];
  return project;
}

const Profilpage = (props) => {
  const project = projectData();
  return (
    <div className="Profilpage bgcolor--white">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <Teaser imgSrc={ project.headerImg } />
          <div className="Profilpage__content">
            <div className="container container--profile">
              <div className="row">
                <div className="col-12">
                  <div className="Hdl Hdl--1">
                    {project.name}
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="Profilpage -description col-lg-6">
                  <div className="text-justify">
                    <p>{project.description}</p>
                  </div>
                </div>
              </div>
              <MasonryGrid data={project.masonry}/>
            </div>
          </div>
        </div>
        {/* <div className="row h-100">
          <div className="col-9 col-lg-12 mx-auto h-100 position-static lg-position-relative">
            <Profil mobile={props.mobile} />
          </div>
        </div> */}
        <div className="row">
          <Teaser imgSrc={ project.footerImg } />
          <div className="container">
            <ScrollTop />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profilpage;
