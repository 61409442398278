import React from 'react';

import './Brands.scss';

function Brands () {
  return (
    <div id="brands" className="Brands section animation animation--fromRight tint tint--85 fixed fixed--default d-flex align-items-center justify-content-center">

      <picture>
        <source data-srcset="
        /static/images/backgrounds/brands/768.jpg 768w,
        /static/images/backgrounds/brands/992.jpg 992w,
        /static/images/backgrounds/brands/1920.jpg 1920w,
        /static/images/backgrounds/brands/2560.jpg 2560w"
        />
        <img className="background-image lazyload" data-sizes="auto" data-src="/static/images/backgrounds/brands/1920.jpg" alt="" />
      </picture>

      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-lg-8 mx-auto text-center">
            <div className="Hdl Hdl--3 text-white">DEMNÄCHST UNSERE HAUSMARKEN HIER!</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brands;
