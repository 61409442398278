import React, { useRef } from 'react';
import Swiper from 'react-id-swiper';
import swiperGlobalOptions from '../Swiper/swiperGlobalOptions';

import './Werke.scss';

import Data from './Data';

const Werke = (props) => {
  const ref = useRef(null);
  const group = Data.Werke[props.group || 'default'];

  const options = {
    ...swiperGlobalOptions,
    // init: false,
    mousewheel: {
      invert: false,
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    //   clickable: true,
    //   dynamicBullets: true,
    // },
    spaceBetween: 10,
    slidesPerView: 3,
    slidesPerGroup: 3,
    breakpoints: {
      768: { // tablet normal ipad
        slidesPerView: 3,
        slidesPerGroup: 3,
        direction: props.position ? props.position : 'vertical'
      },
      736: { // quer bis iphone 6/7/8 plus
        slidesPerView: 2,
        slidesPerGroup: 2,
        direction: 'horizontal'
      },
      414: { // normal bis iphone 6/7/8 plus
        slidesPerView: 1,
        slidesPerGroup: 1,
        direction: props.position ? props.position : 'vertical'
      }
    }
  }

  // function hidePagination () {
  //   if (!props.mobile && !group.pagination) {
  //     document.querySelector('.swiper-pagination').style.display = 'none';
  //   } else {
  //     document.querySelector('.swiper-pagination').style.display = 'block';
  //   }
  // }

  // useEffect(() => {
  //   hidePagination();
  // });



  document.querySelectorAll('.link.delay').forEach(element => {
    element.addEventListener('click', function (event) {
      if (props.mobile) {
        event.preventDefault();
        setTimeout(() => {
          window.open(element.getAttribute('href'), '_self');
        }, 600);
      }
    });
  });

  return (
    <div className={props.fixed ? "Werke h-100 animation animation--fromRight bgcolor--99black100 d-flex align-items-center fixed fixed--default" : "Werke"}>
      <div className="container h-100">
        <div className="row h-100">
          <div className={"col-9 col-lg-8 mx-auto h-100 position-static"}>
            <Swiper {...options} containerClass="swiper-container--page swiper-container position-static" ref={ref}>
              { group.slides.map((slide, index) => (
                <div className="swiper-slide" key={index}>
                  <a className="link delay" href={slide.href}>
                    <picture>
                      <source srcSet={slide.imgSrc + ".jpg"} />
                      <img className="img-fluid" src={slide.imgSrc + ".jpg"} alt={slide.imgAlt} />
                    </picture>
                    <div className="img-caption">{slide.imgAlt}</div>
                  </a>
                </div>
              )) }
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Werke;

