import React from 'react';
import SvgComponent from '../SvgComponent/SvgComponent';

const ScrollTop = () => {
  const srollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="position-relative">
      <button className="btn-top btn-clean float-right" onClick={srollTop}>
        <SvgComponent svgId="angle-up" />
      </button>
    </div>
  )
}

export default ScrollTop;
