import React, { useEffect, useRef } from 'react';
import Legal from '../Legal/Legal';
// import SvgComponent from '../SvgComponent/SvgComponent';
// import Profil from '../Profil';

import './Footer.scss';

function Footer (props) {
  const footerElem = useRef(null);
  // const profileElem = useRef(null);
  // const profileCollapseToggle = useRef(null);

  useEffect(() => {
    // if (props.profile) {
    //   footerElem.current.classList.add('Footer--opened');
    //   profileElem.current.classList.add('Profil--opened');
    //   profileCollapseToggle.current.classList.toggle('collapse-profil--opened');

    // } else {
    //   footerElem.current.classList.remove('Footer--opened');
    //   profileElem.current.classList.remove('Profil--opened');
    //   profileCollapseToggle.current.classList.remove('collapse-profil--opened');
    // }
  });

  return (
    <div id="footer" className="Footer" ref={footerElem}>
      <div id="footerLinks" className="Footer__links">
        <div className="container">
          <div className="row align-items-center align-items-lg-end">
            {/* <div className="col">
              { props.profile ? (
                <button className="collapse-profil" type='button' onClick={() => props.toggleProfile(false)} name="Profil" ref={profileCollapseToggle}>
                  { props.mobile ? (
                    <SvgComponent svgId="angle-right2" />
                  ) : (
                    <SvgComponent svgId="angle-down" />
                  ) }
                </button>
              ) : (
                <button className="collapse-profil" type='button' onClick={() => props.toggleProfile(true)} name="Profil" ref={profileCollapseToggle}>
                  { props.mobile ? (
                    <SvgComponent svgId="angle-right2" />
                    ) : (
                    <SvgComponent svgId="angle-up" />
                  ) }
                </button>
              )}
            </div> */}
            <div className="col text-right">
              { props.mobile ? ('') : (<Legal />) }
            </div>
          </div>
        </div>
      </div>
      {/* <div id="profil" className="Profil" ref={profileElem}>
        <div className="container h-100">
          <div className="row">
            <div className="col text-right">
              <button className="btn btn-clean btn-icon" name="Profil" onClick={() => props.toggleProfile(false)}>
                <SvgComponent svgId="close" />
              </button>
            </div>
          </div>
          <div className="row h-100">
            <div className="col-9 col-lg-12 mx-auto h-100 position-static lg-position-relative">
              <Profil mobile={props.mobile} />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Footer;
