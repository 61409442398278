import React from 'react';

import './Teaser.scss';

function Teaser (props) {
  return (
    <div className="Teaser aspect-ratio aspect-ratio--16-9">
      <picture>
        <source data-srcset={props.imgSrc.sm + ".jpg 768w, " + props.imgSrc.lg + ".jpg 1024w, " + props.imgSrc.xl + ".jpg 1920w, " + props.imgSrc.xxl + ".jpg 3840w" } type="image/jpg" />
        <img className="Teaser__image lazyload" data-src={props.imgSrc.xl + ".jpg" } alt="" data-sizes="auto" />
      </picture>
    </div>
  );
}

export default Teaser;
